import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatTooltip } from "@angular/material/tooltip";
import { ErrorHandlerService } from "@aveva/connect-web-core";
import { BehaviorSubject, of, Subscription, switchMap, tap } from "rxjs";
import { catchError, map } from "rxjs/operators";
import {
  TestRun,
  SpectrumSmokeTestService,
  TestRunRequest,
  TestRunState,
  TestPlanConfig,
  TestPlan,
} from "../../services/spectrum-smoketest.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent, ConfirmationDialogData } from "../../dialogs/confirmation-dialog/confirmation-dialog.component";
import { MatTableDataSource } from "@angular/material/table";

type TestPlanLabels = { [K in TestPlan]: string };

interface TargetSite {
  targetSiteId: string;
  targetRegion: string;
  status: "ok" | "notFound" | "error" | "underMaintenance";
  name: string;
  createdAt: string;
}

interface CreateTestPlanFormControls {
  name: FormControl<string>;
  repetitions: FormControl<number>;
  maxConcurrency: FormControl<number>;
  testPlan: FormControl<TestPlan>;
  targetSite: FormControl<TargetSite | null>;
}

interface FilterFormControls {
  region: FormControl<string[]>;
  state: FormControl<TestRunState[]>;
  failureOnly: FormControl<boolean>;
}

type ExtractFormControlValue<T> = T extends FormControl<infer U> ? U : never;

type FilterFormValues = {
  [K in keyof FilterFormControls]: ExtractFormControlValue<FilterFormControls[K]>;
};

@Component({
  selector: "app-test-runs",
  templateUrl: "./test-runs.component.html",
  styleUrl: "./test-runs.component.scss",
})
export class TestRunsComponent implements OnInit, OnDestroy {
  testPlans: TestPlanLabels = {
    dashboardAndCore: "Dashboard & Core",
    loadTest: "Load Test",
    edgeConnector: "Edge Connector Test",
    dataTest: "🔐 Create Known Data",
    dataConsistency: "Check Data Consistency",
  };

  readonly createRunForm: FormGroup<CreateTestPlanFormControls>;
  readonly filterForm: FormGroup<FilterFormControls>;

  displayedColumns: string[] = [
    "name",
    "targetRegion",
    "testPlan",
    "enqueuedTime",
    "startTime",
    "completedTime",
    "run",
    "maximumDuration",
    "state",
    "success",
    "json",
  ];

  reloadTestRunsTrigger = new BehaviorSubject<void>(undefined);
  reloading = false;
  isSaving = false;
  dataSource = new MatTableDataSource<TestRun>();

  availableRegions: string[] = [];
  availableStates: TestRunState[] = ["pending", "started", "completed", "failed"];

  targetSites: TargetSite[] | null = null;

  private subscriptions: Subscription[] = [];

  constructor(
    private readonly smokeTestService: SpectrumSmokeTestService,
    private readonly errorHandler: ErrorHandlerService,
    private dialog: MatDialog,
    fb: FormBuilder
  ) {
    this.createRunForm = fb.group<CreateTestPlanFormControls>({
      name: new FormControl<string>("Manual test run", {
        nonNullable: true,
        validators: [Validators.required],
      }),
      repetitions: new FormControl<number>(1, {
        nonNullable: true,
        validators: [Validators.required, Validators.min(1), Validators.max(100)],
      }),
      maxConcurrency: new FormControl<number>(1, {
        nonNullable: true,
        validators: [Validators.required, Validators.min(1), Validators.max(100)],
      }),
      testPlan: new FormControl<TestPlan>("dashboardAndCore", {
        nonNullable: true,
        validators: [Validators.required],
      }),
      targetSite: new FormControl<TargetSite | null>(null, {
        nonNullable: false,
        validators: Validators.required,
      }),
    });

    this.filterForm = fb.group<FilterFormControls>({
      region: new FormControl<string[]>([], {
        nonNullable: true,
        validators: Validators.required,
      }),
      state: new FormControl<TestRunState[]>(this.availableStates, {
        nonNullable: true,
        validators: Validators.required,
      }),
      failureOnly: new FormControl<boolean>(false, { nonNullable: true }),
    });

    this.dataSource.filterPredicate = this.filterPredicate;
  }

  ngOnInit() {
    this.subscriptions.push(
      this.smokeTestService
        .getSmokeTestConfiguration()
        .pipe(
          map((config) =>
            config.availableRegions.map<TargetSite>((a) => {
              return {
                targetSiteId: a.siteId,
                targetRegion: a.region,
                status: a.status,
                ...a.siteDetail,
              };
            })
          )
        )
        .subscribe((targetSites) => (this.targetSites = targetSites))
    );

    // Get runs from up to 24 hours ago (TODO add fancy filtering to make this configurable)
    const since = new Date(Date.now() - 1000 * 60 * 60 * 24);
    this.subscriptions.push(
      this.reloadTestRunsTrigger
        .pipe(
          tap(() => (this.reloading = true)),
          switchMap((_) =>
            this.smokeTestService.queryRuns(since).pipe(
              catchError((err) => {
                this.errorHandler.handle(err);
                return of<TestRun[]>([]);
              })
            )
          ),
          tap(() => (this.reloading = false))
        )
        .subscribe((data) => {
          this.dataSource.data = data;
          this.availableRegions = [...new Set(data.map((item) => item.targetRegion))];
          this.filterForm.controls.region.setValue(this.availableRegions);
        })
    );

    this.subscriptions.push(this.filterForm.valueChanges.subscribe((filter) => (this.dataSource.filter = JSON.stringify(filter))));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  createRun() {
    const request: TestRunRequest = {
      name: this.createRunForm.controls.name.value,
      testPlan: this.createRunForm.controls.testPlan.value,
      maxConcurrency: this.createRunForm.controls.maxConcurrency.value,
      repetitions: this.createRunForm.controls.repetitions.value,
    };

    const config: TestPlanConfig = {};

    const targetSite = this.createRunForm.controls.targetSite.value;
    if (targetSite) {
      const { targetSiteId, targetRegion } = targetSite;
      config.targetRegion = targetRegion;

      if (this.createRunForm.controls.testPlan.value === "edgeConnector") {
        config.targetSiteId = targetSiteId;
      }
    }

    request.config = config;
    const observable = this.smokeTestService.createRun(request).pipe(
      catchError((err) => {
        this.errorHandler.handle(err);
        return of<TestRun | null>(null);
      }),
      tap(() => (this.isSaving = false))
    );

    this.isSaving = true;

    if (this.createRunForm.controls.testPlan.value == "dataTest") {
      this.dialog
        .open<ConfirmationDialogComponent, ConfirmationDialogData, boolean>(ConfirmationDialogComponent, {
          data: {
            title: "Creating Well Known Data",
            message: "Are you absolutely certain about the actions you are about to take?",
            warning: "Executing this plan will create new set of data used by the Data Consistency test plan",
          },
        })
        .afterClosed()
        .pipe(
          switchMap((result) => {
            if (!result) {
              return of<TestRun | null>(null);
            }
            return observable;
          })
        )
        .subscribe((testRun) => {
          testRun && this.reloadRuns();
          this.isSaving = false;
        });
    } else {
      observable.subscribe((testRun) => {
        testRun && this.reloadRuns();
        this.isSaving = false;
      });
    }
  }

  reloadRuns() {
    this.reloadTestRunsTrigger.next();
  }

  setClipboardAndConfirm(str: string, confirmTooltip: MatTooltip) {
    navigator.clipboard.writeText(str).then(() => {
      confirmTooltip.disabled = false;
      confirmTooltip.show();
      setInterval(() => (confirmTooltip.disabled = true), confirmTooltip.hideDelay);
    });
  }

  setClipboardJsonAndConfirm(run: TestRun, confirmTooltip: MatTooltip) {
    this.setClipboardAndConfirm(JSON.stringify(run, null, 2), confirmTooltip);
  }

  private filterPredicate(data: TestRun, filter: string): boolean {
    const filterData = JSON.parse(filter) as FilterFormValues | undefined;

    if (filterData == undefined) {
      return false;
    }

    const { region, state, failureOnly } = filterData;

    if (region.indexOf(data.targetRegion) == -1) {
      return false;
    }

    if (state.indexOf(data.state) == -1) {
      return false;
    }

    return !(failureOnly && data.resultsSummary?.allSucceeded == failureOnly);
  }

  renderSelectedTargetSite(): string {
    const targetSite = this.createRunForm.controls.targetSite.value;

    if (!targetSite) {
      return "";
    }
    return `${targetSite.targetSiteId}(${targetSite.targetRegion}) : ${targetSite.status}`;
  }

  getRunStatus(run: TestRun): { style: string; result: string } {
    if (run?.resultsSummary?.partiallySucceeded) {
      return { style: "test-result partial-success", result: "Partial" };
    }
    if (run?.resultsSummary?.allSucceeded) {
      return { style: "test-result ok", result: "OK" };
    }
    if (run?.resultsSummary?.failed) {
      return { style: "test-result failed", result: "Failed" };
    }
    return { style: "test-result", result: "-" };
  }
}
