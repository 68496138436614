import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, shareReplay } from "rxjs";
import { AuthenticationService } from "@aveva/connect-web-core";
import { ApiService, HttpOptions } from "./api.service";

export type TestRunState = "pending" | "started" | "completed" | "failed";
export type TestPlan = "dashboardAndCore" | "loadTest" | "edgeConnector" | "dataTest" | "dataConsistency";

export interface TestPlanConfig {
  targetSiteId?: string;
  targetRegion?: string;
}

export interface TestRunRequest {
  name: string;
  testPlan: TestPlan;
  maxConcurrency: number;
  repetitions: number;
  config?: TestPlanConfig;
}

export interface TestPlanResultsSummary {
  allSucceeded: boolean;
  errored: number;
  failed: boolean;
  succeeded: boolean;
  partiallySucceeded: boolean;
}

export interface TestRun {
  testRunId: string;
  name: string;
  testPlan: TestPlan;
  maxConcurrency: number;
  repetitions: number;
  enqueuedTime: string;
  startTime?: string;
  completedTime?: string;
  state: TestRunState;
  result?: TestRunResult;
  failureDetails?: string;
  resultsSummary?: TestPlanResultsSummary;
  targetSiteId?: string;
  targetRegion: string;
}

export interface TestRunResult {
  successfulCalls: number;
  unsuccessfulCalls: number;
  warnings: number;
  failedStages: string[];
  allOk: boolean;
}

export type SiteDetail = {
  name: string;
  description: string;
  createdAt: string;
};

export type SmokeTestRegion = {
  siteId: string;
  region: string;
  status: "ok" | "notFound" | "error" | "underMaintenance";
  siteDetail: SiteDetail;
};

export interface SmokeTestConfiguration {
  availableRegions: SmokeTestRegion[];
}

@Injectable({
  providedIn: "root",
})
export class SpectrumSmokeTestService extends ApiService {
  constructor(http: HttpClient, authService: AuthenticationService) {
    super(http, authService, ApiService.getSpectrumApiUrl("smoketest"));
  }

  createRun(request: TestRunRequest): Observable<TestRun> {
    return this.post("testRuns", request);
  }

  queryRuns(since?: Date, state?: TestRunState): Observable<TestRun[]> {
    const params: Record<string, string | TestRunState> = {};
    if (since != null) {
      params["since"] = since.toISOString();
    }
    if (state != null) {
      params["state"] = state;
    }

    const httpOptions: HttpOptions = {
      params,
    };

    return this.getList(`testRuns`, httpOptions);
  }

  private cacheSmokeTestConfiguration$: Observable<SmokeTestConfiguration> | null = null;

  getSmokeTestConfiguration() {
    if (!this.cacheSmokeTestConfiguration$) {
      this.cacheSmokeTestConfiguration$ = this.get<SmokeTestConfiguration>(`configuration`).pipe(
        shareReplay({ refCount: true, bufferSize: 1 })
      );
    }
    return this.cacheSmokeTestConfiguration$;
  }
}
